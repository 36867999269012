import React, { useEffect, useState } from "react";
import placeholderAvatar from "../img/placeholder_avatar.png";

export default function Biographies() {
  const [submissions, setSubmissions] = useState([]);

  const form_id = "61f13ffacdf15a0008477236";
  const url = `https://api.netlify.com/api/v1/forms/${form_id}/submissions?state=spam`; //spam = accepted submissions, verified = submissions that are yet to be reviewed
  useEffect(() => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer 5Fhj2-eUct5N2MkZMjfBCJgBeURsrqHw65ISbyHO6mw",
      },
    }).then((response) => {
      response.json().then((data) => setSubmissions(data));
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {submissions.map((submission) => (
        <div
          style={{
            backgroundColor: "#cccccc",
            margin: "10px",
            borderRadius: "10px",
            border: "1px solid #000000",
            padding: "10px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            key={submission.form_id + "name"}
          >
            {submission.data["name"]}
          </div>
          <p
            onClick={() => {
              navigator.clipboard.writeText(submission.data["address"]);
            }}
            key={submission.human_fields.form_id + "address"}
            style={{ marginBottom: "4px", textAlign: "center" }}
          >
            <button>Copy Wallet Address</button>
          </p>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              key={submission.human_fields.form_id + "avatar"}
              src={submission.data["attachment"].url || placeholderAvatar}
              style={{
                maxWidth: "360px",
                height: "360px",
                borderRadius: "10px",
              }}
            />
          </div>
          <p
            key={submission.human_fields.form_id + "biography"}
            style={{
              maxWidth: "360px",
              padding: "10px",
              maxHeight: "480px",
              overflow: "auto",
            }}
          >
            {submission.data["biography"]}
          </p>{" "}
        </div>
      ))}
    </div>
  );
}
