import * as React from "react";
import { navigate } from "gatsby-link";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const form_id = "61f13ffacdf15a0008477236";
const submissionsUrl = `https://api.netlify.com/api/v1/forms/${form_id}/submissions`;

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Publish your biography</h1>
            <p>
              We will review and confirm your submission. Don't forget to check
              back here!
            </p>
            <form
              name="file-upload"
              method="post"
              action="/upload_biography/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="file-upload" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <div className="field">
                <label className="label" htmlFor={"name"}>
                  Your courtesy name
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"name"}
                    onChange={this.handleChange}
                    id={"name"}
                    required={true}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor={"name"}>
                  Your biography
                </label>
                <div className="control">
                  <textarea
                    className="input"
                    name={"biography"}
                    onChange={this.handleChange}
                    id={"biography"}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={"name"}>
                  Your wallet address
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"address"}
                    onChange={this.handleChange}
                    id={"address"}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="file">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      accept="image/*" // todo: also restrict file extension on backend, this is not safe enough
                      name="attachment"
                      onChange={this.handleAttachment}
                    />
                    <span className="file-cta">
                      <span className="file-label">Upload your avatar...</span>
                    </span>
                  </label>
                </div>
              </div>
              <div className="field">
                <button className="button is-link" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
