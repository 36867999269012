import * as React from "react";
import Biographies from "../../components/Biographies";
import Layout from "../../components/Layout";
import Upload from "../../components/Upload";
export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Biographies />
        <Upload />
      </Layout>
    );
  }
}
